<template>
    <div>
        <div class="buttons" >
            <div class="button is-small" v-for="(grade, key) in grades" :id="grade.id" :key="key" :class="{ 'is-success': selected_grades.includes(grade.id) }" @click="toggleButton(grade.id)">{{ grade.name}}</div>
        </div>

        <div class="is-pulled-right " >
            <div class="columns">
                <div class="column">
                    <input class="input" type="date" v-model="start_date" :disabled="loading==true" @change="getBookings()"/>
                    
                </div>
                <div class="column">
                    <input class="input" type="date" v-model="end_date" :disabled="loading==true" @change="getBookings()"/>
                    
                </div>
                <div class="column">
                    <input class="input" v-model="search" :disabled="loading==true" placeholder="search bookings ..."/>
                </div>
            </div>
            
            
        </div>

        <div class="notification is-warning" v-if="loading == true" style="clear:both">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false  && filtered_bookings.length == 0" style="clear:both">
            No bookings can be found matching your search criteria
        </div>

        <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading == false && filtered_bookings.length > 0" style="clear:both" id="bookingstable">
            <thead>
                <tr>
                    <th >Booking</th>
                    <th>Client</th>
                    <th>Ward</th>
                    <th>Cost Code</th>
                    <th>Reference</th>
                    <th>Grade</th>
                    <th>Member</th>
                    <th>Sage</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Invoice</th>
                    <th>Rate</th>
                    <th>Duration</th>
                    <th>Pay Rate</th>
                    <th>Total Pay</th>
                    <th>Charge Rate</th>
                    <th>Total Charge</th>
                    <th>Break</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="row in filtered_bookings" >
                <tr v-for="invoice in row.invoices" :key="invoice.id">
                    
                    <td><a :href="'/bookings/' + row.id" target="_blank">{{ row.id }}</a></td>
                    <td>{{ row.client_name }} <span class="tag is-warning" v-if="row.sleeping_nurse==1">Sleeping Nurse</span></td>
                    <td>{{ row.ward_name }}</td>
                    <td>{{ row.cost_centre_code }}</td>
                    <td>{{ row.reference }}</td>
                    <td>{{ row.grade_code }}</td>
                    <td><a :href="'/members/' + row.member_id" target="_blank">{{ row.forename }} {{ row.surname  }}</a></td>
                    <td>{{ row.sage_number }}</td>
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.start_time }} - {{ row.end_time }}</td>
                    <td>{{ invoice.id }}</td>
                    <td>{{ invoice.description }}</td>
                    <td>{{ (invoice.duration_mins / 60).toFixed(2) }}</td>
                    <td>{{ invoice.pay_rate }}</td>
                    <td>{{ invoice.total_pay }}</td>
                    <td>{{ invoice.charge_rate }}</td>
                    <td>{{ invoice.total_charge }}</td>
                    <td>{{ invoice.break_time_mins }}</td>

                </tr>
            </template>
               
            </tbody>

        </table>

        <p>Found {{ filtered_bookings.length }} bookings</p>

        <button @click="exportXLS()" class="button is-link">Export For Excel</button>
        
    </div>
</template>

<script>
import * as XLSX from 'xlsx'

import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';



export default {
    
      name: 'TrustBookings',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: true,
                   working:false,
                   showModal: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   booking_id: '',
                   showCancelModal: false,
                   end_date: '2023-06-30',
                   start_date : '2023-04-01',
                   grades: [],
                   selected_grades: []
            }
        },
        components: {
            
        },
        methods: {
            toggleButton(buttonId) {
                const index = this.selected_grades.indexOf(buttonId);
                if (index === -1) {
                    // Button not selected, add it to the selectedButtons array
                    this.selected_grades.push(buttonId);
                } else {
                    // Button already selected, remove it from the selectedButtons array
                    this.selected_grades.splice(index, 1);
                }
                this.getBookings();
            },
            exportXLS() {
		
                var wb = XLSX.utils.table_to_book(document.getElementById('bookingstable'), {sheet:"Sheet JS"});
                XLSX.writeFile(wb, 'Processed_Trust_Bookings.xlsx')

            },
            async getBookings() {
                let grades = Object.values(this.selected_grades).join(',');
                if(grades != "")
                {
                    this.bookings = []
                    this.loading=true
                    UtilityService.getSimpleApiData(this.accessToken, "bookings/trust?start_date=" + this.start_date + "&end_date=" + this.end_date + "&grades=" + grades)
                    .then(
                        (bookings => {
                            this.$set(this, "bookings", bookings);
                            this.loading=false;
                            this.working = false;
                        }).bind(this)
                    );
                }
                else {
                    this.loading=false;
                    this.working = false;
                }
            },
            getEndDate() {
            
                var d = new Date();
                
                d.setDate(d.getDate() - 5);
                return d.toISOString().slice(0,10);
                
            },
            
           
            
        },
        computed : {
            filtered_bookings: function () {
            
                let filtered_bookings =  this.bookings

                if(this.search != '' && this.search != null)
                {
                    filtered_bookings = filtered_bookings.filter(
                        row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.reference != null && row.reference.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.client_name != null && row.client_name.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.ward_name != null && row.ward_name.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.id.toString() != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }
                return filtered_bookings
            },
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                
                this.getGrades();
                this.getBookings();
            });
        
        },
    }
</script>