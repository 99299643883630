<template>
    <div>
        
        <div v-if="loading == false ">
            <h4 class="title is-size-4">Client Details</h4>
            <form @submit.prevent="saveClient()">
                
                
                <div class="field ">
                    <div class="columns">
                        <div class="column">
                            <label class="label">Name</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="name" required ></div>
                               
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Code</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="code"  ></div>
                               
                            </div>
                        </div>
                       
                    </div>

                    <div class="field">
                    <label class="label">Address </label>
                    <div class="control columns" >
                        <div class="column"><input class="input" type="text" v-model="address1" required ></div>
                        <div class="column"><input class="input" type="text" v-model="address2"  ></div>
                        <div class="column"><input class="input" type="text" v-model="address3"  ></div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">City / Postcode </label>
                    <div class="control columns" >
                        <div class="column"><input class="input" type="text" v-model="city"  ></div>
                        <div class="column"><input class="input" type="text" v-model="postcode"  ></div>
                        
                    </div>
                </div>

                    <div class="columns">
                        <div class="column">
                            <label class="label">Client Group</label>
                            <div class="control columns" >
                                <div class="column">
                                    <select v-model="client_group_id" required class="input" >
                                        <option value="">Please select ...</option>
                                        <option v-for="row in client_groups" :key="row.id" :value="row.id">{{ row.name }}</option>
                                    </select>

                                </div>
                               
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Billing Profile</label>
                            <div class="control columns" >
                                <div class="column">
                                    <select v-model="client_group_billing_profile_id" required class="input">
                                        <option value="">Please select ...</option>
                                        <option v-for="row in client_group_billing_profiles" :key="row.id" :value="row.id">{{ row.label }} <span v-if="row.email != '' && row.eamil != null">({{ row.email}})</span></option>
                                    </select>
                                </div>
                               
                            </div>
                        </div>
                       
                    </div>

                    <div class="columns">
                        <div class="column">
                            <label class="label">Cost Centre Code</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="cost_centre" required ></div>
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Default Break</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="default_break" required ></div>
                            </div>
                        </div>
                       
                    </div>
                </div>

                <div class="field is-grouped" v-if="this.user.billing==1"><div class="control"><button type="submit" class="button is-success" :disabled="working==true">Save Client Details</button></div></div>
            </form>
            


           
        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'AddClient',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   client: [],
                   loading: false,
                   working: false,
                   accessToken: '',
                   showModal: false,
                   client_groups: [],
                   client_group_billing_profiles: [],
                   user: [{billing: 0}],
                  
                    name: '',
                    code: '',
                    cost_centre: '',
                    default_break: '',
                    client_group_id: this.$route.params.id,
                    client_group_billing_profile_id: 0,
                    address1: '',
                    address2: '',
                    address3: '',
                    city: '',
                    postcode: '',
            }
        },
        methods: {
           
           
            async saveClient() {
                this.working = true;
                
                    var formdata = { 
                        name: this.name,
                        code: this.code,
                        cost_centre: this.cost_centre,
                        default_break: this.default_break,
                        client_group_id: this.client_group_id,
                        client_group_billing_profile_id: this.client_group_billing_profile_id,
                        address1: this.address1,
                        address2: this.address2,
                        address3: this.address3,
                        city: this.city,
                        postcode: this.postcode,
                    }; 

                    BookingService.saveClient(this.accessToken, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.$router.push("/billing/client-groups/" + this.client_group_id);
                        }
                    ).catch((error) => { 
                        console.log(error)
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                        
                    });
            },
            async getClientGroupBillingProfiles() {
                this.client_group_billing_profiles = []

                UtilityService.getSimpleApiData(this.accessToken, "client_group_billing_profiles?client_group_id=" + this.client_group_id)
                .then(
                    (client_group_billing_profiles => {
                        this.$set(this, "client_group_billing_profiles", client_group_billing_profiles);
                    }).bind(this)
                );
            },
            async getClientGroups() {
                
                UtilityService.getSimpleApiData(this.accessToken, "client_groups")
                .then(
                    (client_groups => {
                        this.$set(this, "client_groups", client_groups);
                       
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getClientGroups();
                this.getUserSelf();
                this.getClientGroupBillingProfiles();
                
            });
        
        },
        watch: {
            client_group_id: function() {
                this.getClientGroupBillingProfiles();
            },
           
        },
    }
</script>